import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, Nav, Navbar, Button } from "react-bootstrap";

import logo from "../assets/images/logo.png";
import ExchangeIcon from "../assets/images/icons/header/exchange.png";
import CasinoIcon from "../assets/images/icons/header/casino.png";
import EvolutionIcon from "../assets/images/icons/header/evolution.png";
import AviatorIcon from "../assets/images/icons/header/aviator.png";
import MenuIcon from "../assets/images/menu-icon.png";
import UserIcon from "../assets/images/user-icon.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import WalletIcon from "../assets/images/icons/wallet-icon.png";
import ProfileIcon from "../assets/images/icons/menus/user.png";
import MyBetsIcon from "../assets/images/icons/menus/my-bets.png";
import BonusIcon from "../assets/images/icons/menus/bonus.png";
import ReferralIcon from "../assets/images/icons/menus/referral.png";
import AcStatementIcon from "../assets/images/icons/menus/statement.png";
import LogoutIcon from "../assets/images/icons/menus/logout.png";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";

import { redirect, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import { Link } from "react-router-dom";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { useOutsideAlerter } from "../hooks/useOutsideClick";

const HeaderAfterLogin = () => {
  const location = useLocation();
  const { user, balance, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(!show1);
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState("");
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const catMenu = useRef(null);
  console.log("catMenu 2");
  useOutsideAlerter(catMenu, setShow1);

  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);

  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (show1) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
  }, [show1]);

  const headerMenu = [
    "home",
    "sportsbook",
    "Casino",
    "evolution",
    "aviator",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = headerMenu.find((f) => location?.pathname.includes(f));
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  // console.log("balance", wallet);
  return (
    <header className="header afterlogin">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="me-2 d-block d-md-none"
                  id="menuSidebarHandler"
                >
                  <img
                    src={MenuIcon}
                    alt="User Menu Icon"
                    width={25}
                    id="menuSidebarHandlerImg"
                  />
                </Button>

                <div className="logo">
                  <a href="/sports">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                    {/* <img src={logo} alt="Logo" /> */}
                  </a>
                </div>
              </div>

              <div className="header-left d-none d-lg-block">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="d-none"
                />
                <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                  <Nav className="me-auto">
                    {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                    <Nav.Link
                      href="/sportsbook/Inplay"
                      className={activePage === "sportsbook" ? "active" : ""}
                    >
                      <img src={ExchangeIcon} alt="exchange" />
                      <span>Exchange</span>
                    </Nav.Link>
                    <Nav.Link
                      href="/Casino"
                      className={activePage === "Casino" ? "active" : ""}
                    >
                      <img src={CasinoIcon} alt="casino" />
                      <span>Casino</span>
                    </Nav.Link>
                    <Nav.Link
                      href="/casino/evolution"
                      className={activePage === "evolution" ? "active" : ""}
                    >
                      <img src={EvolutionIcon} alt="evolution" />
                      <span>Evolution</span>
                    </Nav.Link>
                    <Nav.Link
                      href="/casino/spribe/aviator"
                      className={activePage === "aviator" ? "active" : ""}
                    >
                      <img
                        src={AviatorIcon}
                        alt="Aviator"
                        className="aviator"
                      />
                      <span>Aviator</span>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>

              <div className="headerRight">
                <ul>
                  <li>
                    <GamesSearchModal />
                  </li>
                  <li className="balance">
                    <div className="balance-amount">
                      <div className="withdraw">
                        <a href="" onClick={() => navigate("/withdraw")}>
                          W
                        </a>
                      </div>

                      <div className="d-sm-flex align-items-sm-center px-1 px-md-2">
                        <div className="balance align-items-center px-1">
                          <span>Balance:&nbsp;</span>
                          <span>{wallet?.balance}</span>
                        </div>
                        <div className="cash px-1">
                          <span>Cash:</span> <span>{wallet?.cash}</span>
                        </div>
                        <div className="bonus px-1">
                          <span>Bonus:</span> <span>{wallet?.coins}</span>
                        </div>
                      </div>

                      <div className="deposit">
                        <a
                          href="javascript:void(0)"
                          onClick={() => navigate("/gatewaylist")}
                        >
                          D
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className="head-social">
                    <a
                      href={
                        appDetails.TELEGRAM != "" ? appDetails.TELEGRAM : "#"
                      }
                      target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={TelegramIcon} alt="telegram" />
                    </a>
                  </li>

                  <li className="d-none">
                    <a
                      className="head-social"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsappIcon} alt="whatsapp" />
                    </a>
                  </li>
                </ul>

                <Button
                  variant="primary menu-btn"
                  onClick={handleShow}
                  className="d-none d-md-block"
                >
                  <img src={MenuIcon} alt="User Menu Icon" width={25} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        className="user_section"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <img src={UserIcon} alt="User Icon" />
              </div>
              <div className="user-info">
                <div className="user_name">{user?.mstruserid}</div>
                <p>{user?.mobileno}</p>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="container upperbalnace">
            <div className="row">
              <div className="col-6">
                <div className="balance mb-4">
                  <img src={WalletIcon} alt="wallet" />
                  Balance
                </div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-4">
                  {" "}
                  INR {wallet?.balance}
                </div>
              </div>
              {/* <div className="col-12">
                <div className="non_cash_balance">
                  <h6 className="non_cash_balance_heading">
                    <span>
                      <strong> Bonus</strong> <small>Non-Cash-Balance </small>{" "}
                    </span>
                    <span>
                      <strong>{wallet?.coins}</strong>
                    </span>
                  </h6>
                </div>
              </div> */}
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="" className="btn_outline w-100">
                  Withdraw
                </Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary" className="btn_primary w-100">
                  Deposit
                </Button>
              </div>
            </div>
          </div>

          <ul className="menu-items">
            <li>
              <a href="/profile">
                <img src={ProfileIcon} alt="profile" />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <img src={MyBetsIcon} alt="my bets" />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/bonus" className="img_size">
                <img src={BonusIcon} alt="bonus" />
                <span className="bonus-space">Bonus</span>
              </a>
            </li>
            <li>
              <a href="/referral" className="img_size">
                <img src={ReferralIcon} alt="referral" />
                <span className="bonus-space">Referral</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <img src={AcStatementIcon} alt="account statement" />
                <span>Account Statement</span>
              </a>
            </li>

            <li onClick={() => dispatch(logoutUser())}>
              <a href="javascript:void(0)">
                <img src={LogoutIcon} />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>

          <div className="theme-btn">
            <button type="button" className="btn" onClick={toggleClass}>
              <img src={MoonLight} alt="moon" className="dark_theme" />
              <img src={SunLight} alt="sun" className="light_theme" />
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {show1 && <MobLeftbar className="d-block d-md-none" ref={catMenu} />}
    </header>
  );
};

export default HeaderAfterLogin;
